import { FC, useEffect, useState } from "react";
import { CarDataType } from "data/types";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { useData } from "data/data-provider";
import FleetCard from "components/FleetCard/FleetCard";
import CarsListingModel, { CarsListing } from "models/CarsListingModel";
import { CarListingModal } from "models/vehicleModal";
import { useNavigate } from "react-router-dom";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
}) => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const { ourVehicles, setSelectedCar } = useData();
  let maxPrice = ourVehicles
    ? ourVehicles.reduce((acc, current) => Math.max(acc, current.price), 0)
    : 0;
  const [filteredFleet, setFilteredFleet] =
    useState<CarListingModal[]>(ourVehicles);
  const [rangePrices, setRangePrices] = useState([0, maxPrice]);

  useEffect(() => {
    if (
      selectedTypes.length == 0 ||
      Array.from(new Set(ourVehicles!.map((car) => car?.fleet_class))).filter(
        (item): item is any => item !== undefined
      ).length == selectedTypes.length
    ) {
      setFilteredFleet(ourVehicles);
    } else {
      setFilteredFleet(
        ourVehicles!.filter((item: CarListingModal) =>
          selectedTypes.includes(item?.fleet_class?.name ?? "")
        )
      );
    }
  }, [selectedTypes, ourVehicles]);

  useEffect(() => {
    setFilteredFleet(
      ourVehicles!.filter(
        (item: any) =>
          item.price >= rangePrices[0] && item.price <= rangePrices[1]
      )
    );
  }, [rangePrices]);

  const renderCard = (car: CarListingModal) => (
    <FleetCard
      key={car.id}
      data={car}
      onClick={() => {
        navigate("/car-listing-detail");
        setSelectedCar(car);
      }}
    />
  );
  
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
      style={{ minHeight: "80vh" }}
    >
      {/* <div style={{
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}> */}
      <div className="flex flex-col sm:flex-row justify-between">
        <Heading2 heading={"Our Fleet"} subHeading=" " />

        <div className="mb-8 lg:mb-11">
          <TabFilters
            types={[
              ...Array.from(
                new Set(ourVehicles!.map((car) => car?.fleet_class?.name ?? ""))
              ).filter(
                (item): item is string => item !== undefined && item !== ""
              ),
            ]}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            rangePrices={rangePrices}
            setRangePrices={setRangePrices}
            maxPrice={maxPrice}
          />
        </div>
      </div>

      {/* </div> */}
      <div className="nc-SectionGridFeaturePlaces relative">
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
        >
          {filteredFleet?.map(
            (car: any) => car?.active === true && renderCard(car)
          )}
        </div>
        <div className="flex mt-16 justify-center items-center">
          {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
        </div>
      </div>
      {/* <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((car) => (
          <CarCard key={car.id} data={car} />
        ))}
      </div> */}
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
