import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useData } from "data/data-provider";
import DatePickerCustomHeaderOneMonth from "components/DatePickerCustomHeaderOneMonth";
import { changeDate } from "utils/changeDate";

export interface StayDatesRangeInputProps {
  className?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
}) => {
  const [monthsShown, setMonthsShown] = useState<number>(1);
  const {
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    endPeriod,
    setStartDate,
    setEndDate,
  } = useData();
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    changeDate(
      dates,
      startHours,
      startMinutes,
      startPeriod,
      endHours,
      endMinutes,
      endPeriod,
      setStartDate,
      setEndDate
    );
  };
  useEffect(() => {
    const updateMonthsShown = () => {
      if (window.innerWidth >= 768) {
        // md breakpoint (768px)
        setMonthsShown(2);
      } else {
        setMonthsShown(1);
      }
    };

    // Initial check
    updateMonthsShown();

    // Add event listener
    window.addEventListener("resize", updateMonthsShown);
    return () => window.removeEventListener("resize", updateMonthsShown);
  }, []);

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={monthsShown}
          showPopperArrow={false}
          minDate={new Date()}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderOneMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
