import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React from "react";

export interface SectionGridCategoryBoxProps {
  name?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const Locations: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const location = [
    {
      id: "deerfield-beach",
      name: "Deerfield Beach Car Rental",
      href: "/deerfield-beach-car-rental",
    },
    {
      id: "boca-raton",
      name: "Boca Raton Car Rental",
      href: "/boca-raton-car-rental",
    },
    {
      id: "west-palm-beach",
      name: "West Palm Beach Car Rental",
      href: "/west-palm-beach-car-rental",
    },
    {
      id: "pompano-beach",
      name: "Pompano Beach Car Rental",
      href: "/pompano-beach-car-rental",
    },
    {
      id: "coconut-creek",
      name: "Coconut Creek Car Rental",
      href: "/coconut-creek-car-rental",
    },
    {
      id: "delray-beach",
      name: "Delray Beach Car Rental",
      href: "/delray-beach-car-rental",
    },
    {
      id: "coral-springs",
      name: "Coral Springs Car Rental",
      href: "/coral-springs-car-rental",
    },
    {
      id: "fort-lauderdale",
      name: "Fort Lauderdale Car Rental",
      href: "/fort-lauderdale-car-rental",
    },
    {
      id: "boynton-beach",
      name: "Boynton Beach Car Rental",
      href: "/boynton-beach-car-rental",
    },
    {
      id: "davie",
      name: "Davie Car Rental",
      href: "/davie-car-rental",
    },
    {
      id: "sunrise",
      name: "Sunrise Car Rental",
      href: "/sunrise-car-rental",
    },
    {
      id: "sunrise",
      name: "Hollywood FL Car Rental",
      href: "/hollywood-fL-car-rental",
    },
  ];
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;
    default:
      CardComponentName = CardCategoryBox1;
  }
  return (
    <div className={`nc-SectionGridCategoryBox relative p-16 `}>
      <div className="flex mb-8 relative justify-between">
        <Heading>Our Locations</Heading>
      </div>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {location?.map((item) => (
          <CardComponentName key={item.id} locationData={item} />
        ))}
      </div>
    </div>
  );
};

export default Locations;
