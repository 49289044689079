import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  unavailableDates?: Date[];  // New prop to receive unavailable dates
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, unavailableDates = [] }) => { 
  // Check if the current date is unavailable
  const isUnavailable = date ? unavailableDates.some(
    (unavailableDate) => unavailableDate.toDateString() === date.toDateString()
  ) : false;

  return (
    <span
      className={`react-datepicker__day_span ${isUnavailable ? "line-through" : ""}`}
    >
      {dayOfMonth}
    </span>
  );
};

export default DatePickerCustomDay;
