import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HeroSearchForm2Mobile from "components/HeroSearchForm2Mobile/HeroSearchForm2Mobile";
import MobileSearch from "components/MobileSearchForm/MobileSearch";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="hidden lg:flex absolute z-10 inset-x-0 top-1/3 sm:top-1/2 sm:-translate-y-1/2 md:top-1/2 md:-translate-y-1/2 lg:top-[10%] lg:translate-y-0 text-center flex-col items-center space-y-3 lg:space-y-3 xl:space-y-5 bg-white bg-opacity-60 px-4 py-2 mx-auto max-w-5xl rounded-xl">
        <h2 className="font-bold text-[#288DF7] leading-loose text-2xl md:text-3xl lg:text-7xl  rounded-xl">
          Go Places with Us
        </h2>
        <span className="text-[#0A1E36] text-3xl font-semibold ">
          serving South Florida since 2023
        </span>
        <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 ">
          <HeroSearchForm />
        </div>
        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
      </div>
      <div className="absolute lg:hidden z-10 inset-x-4 top-1/5 sm:-translate-y-1/2 md:top-1/4 md:-translate-y-1/2  text-center flex flex-col items-center mx-auto space-y-3 lg:space-y-3 xl:space-y-5 bg-white bg-opacity-60 py-6 rounded-xl">
        <h2 className="font-[800] text-[#288DF7] text-[36px] md:text-[60px] py-2">
          Go Places with Us
        </h2>
        <span className="text-[#0A1E36] text-[22px] font-[500] md:text-[36px] py-2">
          serving South Florida since 2023
        </span>
      </div>
      <div className="lg:hidden absolute z-10 mb-12 lg:mb-0 lg:-mt-40 top-1/3 inset-x-4 mt-4 md:mt-8">
        <MobileSearch />
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
