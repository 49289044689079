import { FC, Fragment, useEffect, useState } from "react";
import { Checkbox, Dialog, DialogPanel, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useNavigate } from "react-router-dom";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import PrivacyPolicy from "containers/PageHome/PrivacyPolicy";
import { globalJson } from "global/global_json";
import { useData } from "data/data-provider";
import { encodeBase64 } from "@progress/kendo-file-saver";
import { getRentalAgreementContent } from "api/rental-agreement/rental-agreement";
import Cancellation from "containers/PageHome/Cancellation";
import RentalAgreement from "containers/PageHome/RentalAgreement";
import { PaymentDone } from "api/stripe/stripe";
import moment from "moment";

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const {
    user,
    startDate,
    endDate,
    pickupLocation,
    dropOffLocation,
    setCouponResponse,
    checkCouponResponseLoading,
    selectedCar,
    searchedPickupLoction,
    searchedDropoffLocation,
    bookingdetails,
    callCouponAPI,
    saveCard,
    couponResponse,
    dropoffLocationType,
    callremoveCouponAPI,
  } = useData();
  const [inputValue, setInputValue] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  let [isAgreementOpen, setIsAgreementOpen] = useState(false);
  let [isTermOpen, setIsTermOpen] = useState(false);
  let [isCancellationOpen, setIsCancellationOpen] = useState(false);
  let [rentalAgreementChecked, setRentalAgreementChecked] = useState(false);
  let [loading, setLoading] = useState(false);
  let [checkoutLoading, setCheckoutLoading] = useState(false);
  let [privacyPolicyCheckedChecked, setPrivacyPolicyChecked] = useState(false);
  let [agreementContent, setAgreementContent] = useState<any>([]);
  const [privacyPolicy, setPrivacyPolicy] = useState<any>([]);
  const [cancellationPolicy, setCancellationPolicy] = useState<any>([]);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const cardElementOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: "white",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  useEffect(() => {
    const openRentalAgreement = async () => {
      if (bookingdetails && globalJson && bookingdetails?.customer?.email) {
        const encodedData = encodeBase64(bookingdetails?.customer?.email); // Encoding data to UTF-8
        const path = `${bookingdetails.id}b6cr4${globalJson.company_id}b6cr4${encodedData}`;

        try {
          const response = await getRentalAgreementContent(path);
          if (response?.data) {
            setAgreementContent(response.data);
            setPrivacyPolicy(response.data.content.privacy_policy);
            setCancellationPolicy(response.data.content.cancellation_policy);
          }
        } catch (error) {
          console.error("Error fetching rental agreement content:", error);
        }

        // setUrl(`https://dev.1now.app/pdf?agreement=${path}`);
      }
    };

    openRentalAgreement();
  }, [bookingdetails, globalJson, user]); // Add dependencies to avoid unnecessary re-renders

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAgreementOpen = (isOpen: boolean) => {
    setIsAgreementOpen(isOpen);
    if (isOpen) {
      toast("Please review the Rental Agreement carefully before proceeding.", {
        style: {
          background: "#fff3cd",
          color: "#856404",
        },
      });
    }
  };

  const handleCouponSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    callCouponAPI(inputValue, bookingdetails?.id);
    setInputValue("");
  };

  const callCheckout = async () => {
    setCheckoutLoading(true);
    setCheckoutLoading(false);
  };

  useEffect(() => {
    if (inputValue == "") {
      setCouponResponse(null);
    }
  }, [inputValue]);

  // Handle payment submission
  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (rentalAgreementChecked !== true) {
      toast.error("Please agree to the Rental Agreement");
      return;
    }
    if (privacyPolicyCheckedChecked !== true) {
      toast.error("Please agree to Privacy & Cancellation Policies");
      return;
    }
    if (!stripe || !elements) return;

    setLoading(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      toast.error("Card element not found.");
      setLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    if (error) {
      toast.error("Payment failed: " + error.message);
      setLoading(false);
      return;
    } else {
      payDone(paymentMethod.id);
    }
  };

  const payDone = async (paymentMethodId: any) => {
    setLoading(true);
    try {
      const data = await saveCard(paymentMethodId);
      setLoading(false);
    } catch (error) {
      console.error("Error saving card", error);
      setLoading(false);
    }
    try {
      const response = await PaymentDone(paymentMethodId, bookingdetails.id);
      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success("Payment completed successfully!");
        navigate(`/booking-details/${bookingdetails?.unique_identifier}`);
      }
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  function close() {
    setIsOpen(false);
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-md lg:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8 mt-4">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full"></div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full mx-auto">
            <div className=" border-b pb-2">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                Invoice
              </h2>
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                Prices may change depending on the length of the rental and the
                price of your rental car.
              </p>
            </div>
            <div className="mt-4">
              <div className="pb-4">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Trip Price
                </span>
              </div>
              <div className="flex justify-between ">
                <span>{selectedCar?.name}</span>
                <div className="flex flex-col items-end">
                  <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice.car_total}
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    ${bookingdetails?.invoice?.avg_car_price_per_day + " x "}
                    {bookingdetails?.invoice.trip_days + "-Days  "}
                  </span>
                </div>
              </div>
            </div>

            {bookingdetails?.invoice?.location_total !== "0.00" && (
              <div className="mt-4">
                <div className="pb-4">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Location Total
                  </span>
                </div>
                {bookingdetails?.invoice?.breakdown?.locations?.map(
                  (location: any) => (
                    <>
                      <div className="flex justify-between ">
                        <span>{location.name}</span>
                        <div className="flex flex-col items-end">
                          <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                            ${location.total}
                          </span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            )}

            {bookingdetails?.invoice?.insurance_total !== "0.00" && (
              <div className="mt-4">
                <div className="pb-4">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Insurance Total
                  </span>
                </div>
                {bookingdetails?.invoice?.breakdown?.insurance?.map(
                  (insurance: any) => (
                    <>
                      <div className="flex justify-between ">
                        <span>{insurance.name}</span>
                        <div className="flex flex-col items-end">
                          <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                            ${insurance.total}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {insurance.rate}
                          </span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            )}

            {bookingdetails?.invoice?.extras_total !== "0.00" && (
              <div className="mt-4">
                <div className="pb-4">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Trip Extras
                  </span>
                </div>
                {bookingdetails?.invoice?.breakdown?.extras?.map(
                  (extra: any) => (
                    <>
                      <div className="flex justify-between ">
                        <span>{extra.name}</span>
                        <div className="flex flex-col items-end">
                          <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                            ${extra.total}
                          </span>
                          <span>${extra.rate}</span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            )}

            {bookingdetails?.invoice?.tax !== "0.00" && (
              <div className="mt-4">
                <div className="pb-4">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Tax & Fee
                  </span>
                </div>
                {bookingdetails?.invoice?.breakdown?.taxes?.map(
                  (taxes: any) => (
                    <>
                      <div className="flex justify-between ">
                        <span>
                          {taxes.description ? taxes.description : taxes.name}
                        </span>
                        <div className="flex flex-col items-end">
                          <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                            ${taxes.total}
                          </span>
                          <span>
                            {taxes.rate.includes("%")
                              ? taxes.rate
                              : `$${taxes.rate}`}
                          </span>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            )}

            {bookingdetails?.invoice?.discount !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between ">
                  <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                    Discount
                  </span>
                  <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice?.discount}
                  </span>
                </div>
              </div>
            )}
            {couponResponse !== null && (
              <div className="mt-4 ">
                <div className="flex justify-between ">
                  <div className="flex flex-wrap justify-between border border-grey rounded-xl min-w-[50%] p-[0.25rem] items-center">
                    <span
                      className="text-lg font-normal text-gray-700 dark:text-gray-300 pl-4 w-28 truncate "
                      title={couponResponse?.coupon_info?.name}
                    >
                      {couponResponse?.coupon_info?.name}
                    </span>
                    <span className="text-lg text-green-600  font-medium pl-1">
                      (
                      {couponResponse?.coupon_info?.coupon_type === "amount"
                        ? `$${couponResponse?.coupon_discount} off`
                        : `${couponResponse?.coupon_percentage}% off`}
                      )
                    </span>
                    <ButtonCircle
                      onClick={() => {
                        setCouponResponse(null);
                        callremoveCouponAPI(
                          couponResponse?.coupon_info?.name,
                          bookingdetails?.id
                        );
                      }}
                      className="self-center"
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </ButtonCircle>
                  </div>
                  <span className="text-2xl text-green-600 flex flex-nowrap items-center font-semibold">
                    -${couponResponse?.coupon_discount}
                  </span>
                </div>
              </div>
            )}

            {couponResponse === null && (
              <form
                onSubmit={handleCouponSubmit}
                className="mt-10 relative w-full"
              >
                <Input
                  value={inputValue}
                  onChange={handleInputChange}
                  required
                  aria-required
                  placeholder="Promo Code"
                  type="text"
                  rounded="rounded-xl w-full pr-0"
                />
                <button
                  type="submit"
                  className="absolute transform top-1/2 -translate-y-1/2 right-[5px] cursor-pointer ttnc-ButtonCircle flex items-center justify-center rounded-xl !leading-none disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 px-8 py-3"
                >
                  {checkCouponResponseLoading ? (
                    <div className="flex justify-center items-center col-span-full">
                      <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                    </div>
                  ) : (
                    "Apply"
                  )}
                </button>
              </form>
            )}
            <div className="mt-4 border-gray-200 dark:border-gray-700 pt-4">
              <div className="flex justify-between ">
                <div>
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Total Rental Price
                  </span>
                  <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                    Overall price rental
                  </p>
                </div>
                <div>
                  <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                    $
                    {bookingdetails?.invoice
                      ?.total_price_after_coupon_without_security
                      ? bookingdetails?.invoice
                          ?.total_price_after_coupon_without_security
                      : bookingdetails?.invoice?.total_price_without_security}
                  </span>
                </div>
              </div>
            </div>

            {bookingdetails?.invoice.security_deposit_total !== "0.00" && (
              <div className="mt-4">
                <div className="flex justify-between ">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Total Deposit
                  </span>
                  <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                    ${bookingdetails?.invoice.security_deposit_total}
                  </span>
                </div>
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="mt-8 pt-8">
                <span className="text-lg text-gray-700 dark:text-gray-300 font-semibold">
                  Confirm Booking
                </span>
                <div className="p-3 mt-4 border rounded-md">
                  <CardNumberElement
                    id="card-number-element"
                    options={cardElementOptions}
                    className="w-full"
                  />
                </div>

                {/* Expiry and CVC on a new row */}
                <div className="flex mt-4 space-x-4">
                  {/* Expiry Date */}
                  <div className="flex-1 border p-3 rounded-md">
                    <CardExpiryElement
                      id="card-expiry-element"
                      options={cardElementOptions}
                      className="w-full"
                    />
                  </div>

                  {/* CVC */}
                  <div className="flex-1 border p-3 rounded-md">
                    <CardCvcElement
                      id="card-cvc-element"
                      options={cardElementOptions}
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              <ButtonPrimary
                className="w-full mt-4 rounded-lg"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex justify-center items-center col-span-full">
                    <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  </div>
                ) : (
                  "Pay and Book"
                )}
              </ButtonPrimary>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const mainSecion = () => {
    return (
      <div className="flex flex-col  sm:rounded-md sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className=" w-full aspect-w-4 aspect-h-2 rounded-2xl overflow-hidden">
            <img
              alt=""
              className="absolute inset-0 object-cover w-full"
              sizes="200px"
              src={
                (selectedCar?.fleet_photos?.length ?? 0) > 0
                  ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                  : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
              }
            />
          </div>
          <div>
            <div className="mt-4 max-w-xs">
              <p className="font-bold text-lg">Pick up Details</p>
              <p>
                {" "}
                {searchedPickupLoction
                  ? searchedPickupLoction
                  : pickupLocation?.name}
              </p>
              <div className="flex justify-between">
                <span>{moment(startDate).format("ddd, D MMM YYYY")}</span>
                <span>{moment(startDate).format("hh:mm A")}</span>
              </div>
            </div>
            <div className="mt-4 max-w-xs">
              <p className="font-bold text-lg">Drop off Details</p>
              <p>
                {" "}
                {dropoffLocationType === false &&
                searchedDropoffLocation.length > 0
                  ? searchedDropoffLocation
                  : dropOffLocation?.name
                  ? dropOffLocation?.name
                  : dropoffLocationType === true &&
                    searchedPickupLoction.length > 0
                  ? searchedPickupLoction
                  : pickupLocation?.name}
              </p>
              <div className="flex justify-between">
                <span>{moment(endDate).format("ddd, D MMM YYYY")}</span>
                <span>{moment(endDate).format("hh:mm A")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const termsSection = () => {
    return (
      <div className="w-full flex flex-col rounded-md sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 mt-4">
        <div>
          <h3 className="text-2xl font-bold text-[#111827] dark:text-white">
            Terms & Conditions
          </h3>

          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-md overflow-hidden z-10">
            <Checkbox
              checked={rentalAgreementChecked}
              onChange={(checked) => {
                handleAgreementOpen(true);
              }}
              className="group size-7 rounded-md p-1 ring-1 ring-primary-200 data-[checked]:bg-primary-200 data-[checked]:bg-opacity-20 ring-inset  mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-primary-200 ${
                  rentalAgreementChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I have read and accept all the terms and conditions mentioned
                below and i verify that all the details mentioned below are
                correct
              </div>
              <div
                className="text-gray-800 dark:text-gray-200 text-xl font-bold cursor-pointer underline"
                onClick={() => {
                  handleAgreementOpen(true);
                }}
              >
                Rental Agreement{" "}
              </div>
            </div>
          </div>
          <div className="flex gap-2 mt-6 p-4 border border-neutral-200 dark:border-neutral-700 rounded-md overflow-hidden z-10">
            <Checkbox
              checked={privacyPolicyCheckedChecked}
              onChange={(checked) => {
                setPrivacyPolicyChecked(checked);
              }}
              className="group size-7 rounded-md p-1 ring-1 ring-primary-200 data-[checked]:bg-primary-200 data-[checked]:bg-opacity-20 ring-inset  mr-4"
            >
              <CheckIcon
                className={`size-5 stroke-primary-200 ${
                  privacyPolicyCheckedChecked ? "visible" : "invisible"
                }`}
              />
            </Checkbox>
            <div>
              <div className="text-gray-400 text-lg font-medium ">
                I have read and accept all the policy and cancellation policy
                mentioned in the rental agreement and i verify that all the
                details mentioned in the agreement are correct
              </div>

              <span
                className="text-gray-800 text-xl font-bold cursor-pointer underline dark:text-white"
                onClick={() => {
                  setIsTermOpen(true);
                }}
              >
                Privacy Policy
              </span>
              <span className="text-gray-800 text-xl font-bold cursor-pointer mx-2 dark:text-white">
                &
              </span>
              <span
                className="text-gray-800 text-xl font-bold cursor-pointer underline dark:text-white"
                onClick={() => {
                  setIsCancellationOpen(true);
                }}
              >
                Cancellation Policy
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  function padToTwoDigits(number: number) {
    return number.toString().padStart(2, "0");
  }

  return (
    <>
      <div className={`nc-CheckOutPagePageMain ${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 ">
          <div className="max-w-2xl mx-auto">
            {mainSecion()}
            {termsSection()}
            {renderSidebar()}
          </div>
        </main>
      </div>

      <Transition appear show={isTermOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsTermOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsTermOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <PrivacyPolicy terms={privacyPolicy} />
                        </div>
                      </div>
                    </div>

                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-md"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsTermOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isAgreementOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsAgreementOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsAgreementOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <RentalAgreement data={agreementContent} />
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-md"
                        onClick={() => {
                          setRentalAgreementChecked(true);
                          setIsAgreementOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCancellationOpen} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={() => {
            setIsCancellationOpen(false);
          }}
          static={true}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <DialogPanel className="relative h-full w-full overflow-hidden flex-1 flex flex-col justify-between bg-neutral-800 ">
                  <>
                    <div className="absolute left-4 top-4 z-0">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={close}
                      >
                        <XMarkIcon
                          onClick={() => {
                            setIsCancellationOpen(false);
                          }}
                          className="w-5 h-5 text-black dark:text-white"
                        />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-auto">
                      <div className="flex-1 flex flex-col bg-white dark:bg-neutral-800 z-10">
                        <div className="flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto">
                          <Cancellation terms={cancellationPolicy} />
                        </div>
                      </div>
                    </div>
                    <div className=" justify-end px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex">
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-md"
                        onClick={() => {
                          setPrivacyPolicyChecked(true);
                          setIsCancellationOpen(false);
                        }}
                      >
                        Accept
                      </ButtonPrimary>
                    </div>
                  </>
                </DialogPanel>
              </Transition>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CheckOutPagePageMain;
